<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('sft-profile-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.sftp.label.id') }}</b-th>
                            <b-th>{{ $t('settings.sftp.label.name') }}</b-th>
                            <b-th>{{ $t('settings.sftp.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="profile in profiles" :key="profile.id">
                            <b-td>{{ profile.id }}</b-td>
                            <b-td>{{ profile.name }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('sft-profile-modal', {id: profile.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button variant="warning"
                                          class="mr-2"
                                          size="sm"
                                          @click="testConnection(profile.id)"
                                >
                                    {{ $t('common.button.test') }}
                                </b-button>

                                <b-button @click="deleteProfile(profile.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsExportSftp',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            profiles: [],
        }
    },
    methods: {
        ...mapGetters('SftProfile', ['getSftpProfiles']),
        shown() {
            const profilesFetch = this.$store.dispatch('SftProfile/fetchSftpProfiles')

            Promise.all([profilesFetch])
                .then(() => {
                    this.profiles = this.getSftpProfiles()
                })
                .finally(() => {
                    this.loading = false
                })
            this.loading = false
        },
        deleteProfile(id) {
            this.$removeConfirm('SftProfile/deleteSftpProfile', id, this.shown)
        },
        testConnection(id) {
            this.$store.dispatch('SftProfile/testSftpProfile', id)
                .then((response) => {

                    if (response.data.success===true) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            'OK',
                            'success'
                        )
                    } else {
                        this.$root.showMessage(
                            this.$t('common.form.title.error'),
                            response.data.message || 'Failed',
                            'danger'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>